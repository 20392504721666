@import "settings";

@font-face {
  font-family: 'LH Light';
  src: url('../../assets/fonts/LufthansaTextWeb-Light.eot?#iefix');
  src: url('../../assets/fonts/LufthansaTextWeb-Light.eot?#iefix') format('eot'),
  url('../../assets/fonts/LufthansaTextWeb-Light.woff') format('woff'),
  url('../../assets/fonts/LufthansaTextWeb-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LH';
  src: url('../../assets/fonts/LufthansaTextWeb-Regular.eot?#iefix');
  src: url('../../assets/fonts/LufthansaTextWeb-Regular.eot?#iefix') format('eot'),
  url('../../assets/fonts/LufthansaTextWeb-Regular.woff') format('woff'),
  url('../../assets/fonts/LufthansaTextWeb-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LH Light-Head';
  src: url('../../assets/fonts/LufthansaHeadWeb-Light.eot?#iefix');
  src: url('../../assets/fonts/LufthansaHeadWeb-Light.eot?#iefix') format('eot'),
  url('../../assets/fonts/LufthansaHeadWeb-Light.woff') format('woff'),
  url('../../assets/fonts/LufthansaHeadWeb-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LH Thin-Head';
  src: url('../../assets/fonts/LufthansaHeadWeb-Thin.eot?#iefix');
  src: url('../../assets/fonts/LufthansaHeadWeb-Thin.eot?#iefix') format('eot'),
  url('../../assets/fonts/LufthansaHeadWeb-Thin.woff') format('woff'),
  url('../../assets/fonts/LufthansaHeadWeb-Thin.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LH Medium';
  src: url('../../assets/fonts/LufthansaTextWeb-Bold.eot?#iefix');
  src: url('../../assets/fonts/LufthansaTextWeb-Bold.eot?#iefix') format('eot'),
  url('../../assets/fonts/LufthansaTextWeb-Bold.woff') format('woff'),
  url('../../assets/fonts/LufthansaTextWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LH Medium-Head';
  src: url('../../assets/fonts/LufthansaHeadWeb-Bold.eot?#iefix');
  src: url('../../assets/fonts/LufthansaHeadWeb-Bold.eot?#iefix') format('eot'),
  url('../../assets/fonts/LufthansaHeadWeb-Bold.woff') format('woff'),
  url('../../assets/fonts/LufthansaHeadWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LH Icon';
  src:url('../../assets/fonts/lh-iconfont.eot?#iefix');
  src:url('../../assets/fonts/lh-iconfont.eot?#iefix') format('eot'),
 url('../../assets/fonts/lh-iconfont.woff') format('woff'),
 url('../../assets/fonts/lh-iconfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
