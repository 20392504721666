.step {
  display: none;
  width: 100%;
  color: $color-blue;
  padding: 60px 0;
  padding-top: 0;
  @include clearfix();

  &[data-step="form"] {
    padding: 0;
  }

  &__question-container {
    display: block;
    float: left;
    width: 60%;
    padding-right: 30px;

    &.full-width {
      width: 100%;
      float: none;
      padding-right: 0;
    }
  }

  &__answer-container {
    display: block;
    float: left;
    width: 40%;
    padding-top: 10px;
  }

  &__answer {
    margin-bottom: 30px;
    display: block;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__question-head {
    display: block;
    font-family: 'LH Medium-Head';
    font-size: 20px;
    margin-top: 20px;
  }

  &__question {
    font-size: 43px;
    font-family: 'LH Thin-Head';

    b {
      font-family: 'LH Medium-Head';
    }
  }

  .brands {
    display: block;
    position: relative;
    @include clearfix();
    padding-top: 20px;
    margin-top: 40px;

    &__triangle {
      display: none;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 70px 0 70px;
      border-color: $color-yellow transparent transparent transparent;
    }

    &__body {
      display: none;
      padding: 8px;
      background: $color-grey-4;
    }

    &__relation {
      display: inline-block;
      width: 100%;
      background: #fff;
      padding: 0;
      font-family: 'LH Medium-Head';
      font-size: 12px;
      font-weight: bold;
    }

    &__category {
      position: relative;
      float: left;
      display: block;
      width: calc(25% - 10px);
      margin-right: 10px;

      .head {
        display: block;
        background: $color-grey-4;
        padding: 15px 10px;
      }

      &.active {
        .head {
          background: $color-blue;
        }
        span {
          color: #fff;
        }

        .brands__body {
          display: block;
        }

        .brands__triangle {
          display: block;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      > span {
        font-size: 13px;
        font-family: 'LH';
        color: $color-grey-3;
      }
    }
  }
}
