.index {
  display: none;
  background: $color-blue;
  color: #fff;
  width: 100%;
  height: 100vh;
  max-height: 475px;
  padding: 60px 40px;
  @include clearfix();


  &__icon {
    display: block;
    width: 100px;
    float: left;
    padding: 20px;
    text-align: center;

    svg {
      display: block;
      width: 90px;
      height: 90px;
      margin-left: -15px;

      * {
        fill: #fff;
      }
    }

    span {
      display: block;
      font-family: 'LH Light-Head';
      font-size: 13px;
      text-align: center;
    }
  }

  &__intro {
    display: block;
    width: calc(100% - 100px);
    float: left;
    padding: 30px 20px;
    padding-left: 5px;
  }

  &__light-head {
    display: block;
    font-family: 'LH Light-Head';
    font-size: 43px;
    line-height: 1.2;
  }

  &__medium-head {
    line-height: 1.2;
    display: block;
    font-family: 'LH Medium-Head';
    font-size: 43px;
    margin-bottom: 30px;
  }

  p {
    font-family: 'LH Light-Head';
    font-size: 13px;
  }
}
