.button {
  background: $color-blue;
  border: 1px solid $color-blue;
  padding: 12px;
  color: #fff;
  font-family: 'LH';
  max-width: 240px;
  height: 83px;
  width: 100%;
  font-size: 14px;
  text-align: left;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
  outline: none;

  &:hover {
    background: $color-blue-hover;
    border-color: $color-blue-hover;

    &:after {
      border-color: transparent transparent transparent $color-blue-hover;
    }
  }

  &:after {
    position: absolute;
    content: '';
    top: -1px;
    right: -21px;
    border-style: solid;
    border-width: 41px 0 41px 20px;
    border-color: transparent transparent transparent $color-blue;
  }

  &.big-button {
    &:after {
      border-width: 41px 0 41px 20px;
    }
  }
}

.start-button {
  margin-top: 20px;
  background: #fff;
  color: $color-blue;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'LH Medium-Head';
  padding: 13px 10px;
}

.last-button {
  display: block;;
  color: #fff;
  background: $color-blue;
}

.send-contact-form {
  cursor: pointer;
  background: $color-blue;
  color: #fff;
  font-family: 'LH Medium-Head';
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 3px;

  &:hover {
    background: $color-blue-hover;
  }
}
