body {
  &[data-step="0"] {
    .header {
      display: none;
    }
  }
}

.header {
  display: block;
  font-family: 'LH Medium-Head';
  font-size: 13px;
  color: $color-blue;
  padding-bottom: 98px;
  position: relative;

  &__icon {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    background: $color-blue;
    border-radius: 50%;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 20px;
      height: 20px;

      * {
        fill: #fff;
      }
    }

    &:hover {
      background: $color-blue-hover;
    }
  }

  &__text {
    display: inline-block;
    width: calc(100%  - 100px);
    margin-left: 40px;
    vertical-align: 10px
  }
}
