.form {
  &__head {
    font-family: 'LH Medium-Head';
    font-size: 22px;
    margin-bottom: 30px;

    span {
      display: block;
      font-family: 'LH Light-Head';
    }
  }

  h3 {
    font-family: 'LH Medium-Head';
    font-size: 22px;
  }

  form {
    span.only-with-data {
      display: none;
    }

    &[data-with-data="1"] {
      span.only-with-data {
        display: block;
      }
    }
  }
}

::-webkit-input-placeholder {
  color: $color-grey-4;
  opacity: 1;
}

:-moz-placeholder {
  color: $color-grey-4;
  opacity: 1;
}

::-moz-placeholder {
  color: $color-grey-4;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $color-grey-4;
  opacity: 1;
}

.form {
  @include grid-settings($special-gutter-width: 15px) {
    &__row {
      @include grid-row();

      &:last-of-type {
        .form__field {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &.gate-news-form {
      h2 {
        margin-top: 40px;
        color: $color-blue;
      }

      p {
        margin-bottom: 40px;
      }
    }

    &__field {
      @include column-settings();
      margin-bottom: 15px;
      padding-bottom: 20px;
      position: relative;

      &.thank-you {
        margin-top: 20px;
        font-size: 19px;
      }

      &.label-margin-bottom {
        label {
          margin-bottom: 10px;
        }
      }

      &.error {

        #{$input-selectors} {
          border: 1px solid $sxs-color-ui-negative;
        }

        > .error-message {
          display: block;
        }
      }

      .error-message {
        display: none;
        position: absolute;
        bottom: 0;
        color: $sxs-color-ui-negative;
      }
    }

    .form-error,
    .mail-error {
      display: none;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 20px;
      border-radius: 3px;
      border: 1px solid $sxs-color-ui-negative;
      background-color: $sxs-color-ui-negative-m1;
      color: $sxs-color-ui-negative;

      &.show {
        display: block;
      }
    }
  }



  label {
    display: block;
    margin: 0 0 2px 0;
    font-size: 19px;
    font-family: 'LH Light';

    span.optional {
      color: $color-blue-hover;
    }
  }

  select {
    height: 39px;
  }

  textarea {
    resize: none;
    height: 200px !important;
    width: 100%;
  }

  .form__radio-label {
    display: inline-block;
    margin: 0 10px 0 0;
    float: left;

    &:last-of-type {
      margin-right: 0;
    }
  }

  #{$input-selectors} {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $color-grey-3;
    padding: 10px 14px;
    color: #000;
    font-weight: bold;
    transition: background-color $animation-speed, border-color $animation-speed;

    &:focus {
      border-color: $color-blue;
      outline: none;
    }
  }
}
