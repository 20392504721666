.footer {
  margin-top: 20px;
  &__intro,
  &__form-link {
    display: inline-block;
  }

  &__intro {
    font-size: 13px;
    font-family: 'LH Medium-Head';
    display: inline-block;
  }

  &__form-link {
    display: inline-block;
    margin-right: 13px;
    padding-left: 30px;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      cursor: pointer;
      font-size: 12px;
      font-family: 'LH Light-Head';
    }

    svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
    }

    &:hover {
      color: $color-blue-hover;

      svg {
        * {
          fill: $color-blue-hover;
        }
      }
    }
  }

  &.footer-for-step-3 {
    .footer__form-link {
      padding-left: 50px;
      margin-top: 10px;

      svg {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
      }

      a {
        text-decoration: underline;
        font-size: 16px;
        color: $color-blue;

        &:visited {
          color: $color-blue;
        }

        &:hover {
          color: $color-blue-hover;
        }
      }

      &:hover {
        color: $color-blue;

        svg {
          * {
            fill: $color-blue;
          }
        }
      }
    }
  }
}
