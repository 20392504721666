html {
  background: #fff;
}
body {
  max-width: 780px;
  font-family: 'LH Light';
  line-height: 1.3;

  * {
    box-sizing: border-box;
  }

  .container {
    background: #fff;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

$color-blue: #05164d;
$color-blue-hover: #52627c;
$color-yellow: #ffad00;
$color-grey: #e6e6e6;
$color-grey-2: #c2c2c2;
$color-grey-3: #9c9d9d;
$color-grey-4: #f4f4f4;
$sxs-color-ui-negative: #FF000C;
$sxs-color-ui-negative-m1: #FFF0EC;
$input-selectors: 'select, textarea, input:not([type]), input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"]';

$gutter-width: 30px;
$animation-speed: 300ms;


@mixin grid-settings($special-gutter-width) {
  $old-gutter-width: $gutter-width;
  $gutter-width: $special-gutter-width !global;

  @content;

  $gutter-width: $old-gutter-width !global;
}

@mixin grid-row() {
  @if $gutter-width / 2 != 0px {
    margin-left: -$gutter-width / 2;
    margin-right: -$gutter-width / 2;
  }

  @include clearfix();
}

@mixin grid-column($columns) {
  @if $gutter-width / 2 != 0px {
    width: calc(
      #{0% + nth($columns, 1) / nth($columns, 3) * 100} - #{$gutter-width}
    );
    margin-left: $gutter-width / 2;
    margin-right: $gutter-width / 2;
  } @else {
    width: #{0% + nth($columns, 1) / nth($columns, 3) * 100};
  }

  float: left;
}

@mixin column-settings() {
  @include grid-column(1 of 1);

  &--1-2 {
    @include grid-column(1 of 2);
  }

  &--1-3 {
    @include grid-column(1 of 3);
  }

  &--2-3 {
    @include grid-column(2 of 3);
  }

  &--1-4 {
    @include grid-column(1 of 4);
  }

  &--2-4 {
    @include grid-column(2 of 4);
  }

  &--3-4 {
    @include grid-column(3 of 4);
  }

  &--375 {
    width: calc(37.5% - 15px);
    margin-left: 7.5px;
    margin-right: 7.5px;
    float: left;
  }

  @for $i from 1 through 4 {
    &--#{$i}-5 {
      @include grid-column($i of 5);
    }
  }

  @for $i from 1 through 7 {
    &--#{$i}-8 {
      @include grid-column($i of 8);
    }
  }

  @for $i from 1 through 11 {
    &--#{$i}-12 {
      @include grid-column($i of 12);
    }
  }
}
