.form-process {
  position: absolute;
  bottom: 0;

  ul {
    list-style: none;
    padding: 0;
    color: $color-grey-3;
    font-size: 10px;
    font-family: 'LH Light-Head';
  }
}
